import * as React from 'react';
import { ga_event } from '../Analytics';
export type CameraType = 'back' | 'front';

/**
 * provides tools to take a photo or record a video.
 * will re-initialize when camera is updated.
 */
export function useMediaRecorder({
  canvasRef,
  camera
}: {
  canvasRef: React.MutableRefObject<HTMLCanvasElement>;
  camera: CameraType;
}) {
  const [isRecording, setIsRecording] = React.useState(false);
  const [recording, setRecording] = React.useState<{
    blob: Blob;
    type: 'image' | 'video';
  } | null>(null);

  const mediaRecorderRef = React.useRef<MediaRecorder | null>(null);
  const chunks = React.useRef<Blob[]>([]);
  const timeSlice = 500;

  const canvas = canvasRef.current;

  const initializeMediaRecorder = React.useCallback(
    async ({
      camera,
      canvas
    }: {
      camera: CameraType;
      canvas: HTMLCanvasElement;
    }) => {
      console.log('initialize MediaRecorder', { camera });

      const mediaStream = canvas.captureStream(60);
      const micStream = await navigator.mediaDevices.getUserMedia({
        audio: true
      });

      /** combine tracks into 1 MediaStream */
      const mixedStream = new MediaStream([
        ...mediaStream.getTracks(),
        ...micStream.getTracks()
      ]);

      /** create MediaRecorder */
      const mediaRecorder = new MediaRecorder(mixedStream);
      mediaRecorder.onstart = () => {
        console.info('mediaRecorder: start', { camera });
        setIsRecording(true);
      };
      mediaRecorder.onstop = (event) => {
        console.info('mediaRecorder: stop', { camera });
        setIsRecording(false);

        const mediaType = chunks.current.length > 1 ? 'video' : 'image';

        // handle video recording
        if (mediaType === 'video') {
          console.info('video was recorded', { camera });
          const blob = new Blob(chunks.current, { type: 'video/mp4' });

          // video length
          const videoLength = Math.round(timeSlice * chunks.current.length / 1000);
          // fire ga event
          console.info('video length: ', {videoLength});
          ga_event(`ade-recorded-video-${videoLength}-${camera}`, {});

          setRecording({
            blob,
            type: 'video'
          });
          chunks.current = [];
          return;
        }

        // handle image recording
        if (mediaType === 'image') {
          console.info('picture was taken', { camera });
          canvas.toBlob((canvasBlob) => {
            if (!canvasBlob) {
              console.warn('could not save image to blob');
              return;
            }

          // fire ga event
          console.info('image capture event');
          ga_event(`ade-captured-image-${camera}`, {});

            setRecording({
              blob: canvasBlob,
              type: 'image'
            });
            chunks.current = [];
          });
        }
      };

      mediaRecorder.ondataavailable = (e) => {
        chunks.current.push(e.data);
      };

      /** set to ref so it is avail to other scopes */
      mediaRecorderRef.current = mediaRecorder;
    },
    []
  );

  /** initialize Media Recorder
   *  re-initialize when camera changes so we know the CameraType used.
   */
  React.useEffect(() => {
    if (!canvas) return;
    initializeMediaRecorder({ canvas, camera });
  }, [camera, canvas, initializeMediaRecorder]);

  const startRecording = React.useCallback(async () => {
    if (mediaRecorderRef.current?.state === 'recording') return;
    /** if a recording is already saved, clear it to start a new one */
    if (recording) setRecording(null);

    const mediaRecorder = mediaRecorderRef.current;
    /** add a timeslice value here to cut content into multiple chunks,
     *  each chunk is the amount of milliseconds provided.
     *  this way, if we only have 1 chunk, we know it is an image.
     */
    mediaRecorder?.start(timeSlice);
  }, [recording]);

  const stopRecording = React.useCallback(() => {
    mediaRecorderRef.current?.stop();
  }, []);

  return {
    start: startRecording,
    stop: stopRecording,
    isRecording,
    recording,
    clearRecording: () => {
      chunks.current = [];
      setRecording(null);
    }
  };
}
