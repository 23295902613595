import { useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';

export function useSentry() {
  const sentryInitialized = useRef(false);
  useEffect(() => {
    if (sentryInitialized.current === false) {
      Sentry.init({
        dsn: 'https://7c01d5671490eae786bc71792d1e5bf0@o1021155.ingest.sentry.io/4506066525487104',
        integrations: [
          new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost']
          })
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0 // Capture 100% of the transactions
      });
      sentryInitialized.current = true;
    }
  }, []);
}
