import * as React from 'react';
import Snap from './Snap';
import { isMobile } from 'react-device-detect';
import DesktopFallback from './DesktopFallback';
import { useSentry } from './third-party-services/useSentry';
import { ga_event } from './Analytics';

function reportCampaign() {
  // getting the campaing parameter
  // Parse the query parameters from the URL
  const params = new URLSearchParams(window.location.search);

  const campaign = params.get('campaign');

  if (campaign) {
    ga_event(`ade-campaign-${campaign}`, {});
    /** redirect users to the url with utm_campaign for better reporting in ga dashboard. */
    const newUrl = window.location.href.replace('campaign', 'utm_campaign');
    window.location.replace(newUrl);
  }
}

const App: React.FC = () => {
  useSentry();
  React.useEffect(() => {
    reportCampaign();
  }, []);

  return (
    <div className="relative h-[100dvh] w-full bg-black">
      {!isMobile && (
        <DesktopFallback
          title="WEAR NOW"
          instructions="Scan the QR code with your mobile"
        />
      )}
      {isMobile && <Snap />}
    </div>
  );
};

export default App;
