import * as React from 'react';
import { isMobile } from 'react-device-detect';
import useResizeObserver from 'use-resize-observer';

const sources: {
  desktop: Record<string, string>;
  mobile: Record<string, string>;
} = {
  desktop: {
    720: 'https://player.vimeo.com/progressive_redirect/playback/875196141/rendition/720p/file.mp4?loc=external&log_user=0&signature=f5becdd29d0e70e0e24408ab756ac564fdb9f768fdd3596cf50192a23d6b1893',
    540: 'https://player.vimeo.com/progressive_redirect/playback/875196141/rendition/540p/file.mp4?loc=external&log_user=0&signature=6227bff7349e35119e559300a6ebce14ef51e20d7a20bd9f51e6b8e4fc642de7'
  },
  mobile: {
    1080: 'https://player.vimeo.com/progressive_redirect/playback/874872437/rendition/1080p/file.mp4?loc=external&log_user=0&signature=5471eb9451c3f792190f408f6a2ce5db0993bc17c2b2b55f5a7e641275d04d88',
    720: 'https://player.vimeo.com/progressive_redirect/playback/874872437/rendition/720p/file.mp4?loc=external&log_user=0&signature=d27a2fa3898de3a8897b6367a92d5bd00aeb3b7ae7633119f86e443178b0f2e6',
    540: 'https://player.vimeo.com/progressive_redirect/playback/874872437/rendition/540p/file.mp4?loc=external&log_user=0&signature=d7b0fc9200f287c9bcea222afff19bf4238b53c30f619d5c20725cc2ca57f54b',
    360: 'https://player.vimeo.com/progressive_redirect/playback/874872437/rendition/360p/file.mp4?loc=external&log_user=0&signature=69b036fdc2bdd1db6842393a093f3191a65cb73d694e8377f0ca6a4738566aae'
  }
};

const posterImg = isMobile
  ? 'https://tf-web-assets.imgix.net/images/ade/mobile-bg.png?auto=format'
  : 'https://tf-web-assets.imgix.net/images/ade/desktop-bg.png?auto=format';

export function VideoBg() {
  const { ref, width = 1 } = useResizeObserver<HTMLDivElement>();
  const [isMetadataLoaded, setIsMetadataLoaded] = React.useState(false);

  const handleLoadedMetadata = (
    e: React.SyntheticEvent<HTMLVideoElement, Event>
  ) => {
    setIsMetadataLoaded(true);
    const target: HTMLVideoElement = e.currentTarget;
    target.play();
  };

  const screenSources = isMobile ? sources.mobile : sources.desktop;

  // check the screen size and set the best video src size for best user experience
  // grabbed from VideoLoop - studio-octopus
  const sourceEntries = React.useMemo(() => {
    const entries = Object.entries(screenSources)
      .sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
      .map((e, i) => [parseInt(e[0]), e[1]]);
    entries[entries.length - 1][0] = Infinity;
    return entries;
  }, [screenSources]);

  // grabbed from VideoLoop - studio-octopus
  const selectedSource = React.useMemo(() => {
    const bestSizeOption = sourceEntries.filter((entry) => {
      return width < entry[0];
    });
    return bestSizeOption[0][1];
  }, [sourceEntries, width]);

  return (
    <div
      ref={ref}
      className="absolute inset-0 h-full w-full bg-black opacity-30"
    >
      <video
        className="h-full w-full transition-opacity duration-500 ease-out-quart object-cover object-center"
        onLoadedMetadata={handleLoadedMetadata}
        poster={posterImg}
        preload="metadata"
        src={selectedSource as string}
        style={{ opacity: isMetadataLoaded ? 1 : 0 }}
        loop
        muted
        playsInline
      />
    </div>
  );
}
